.social-media-section-container {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
}

.social-media-wrapper {
  padding: 2rem;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
  height: fit-content;
}

.contact-me-img > img {
  width: 300px;
  border: 1px solid gray;
  border-radius: 0.2rem;
}

.devider {
  max-width: 900px;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.contact-me-img {
  margin-right: 4rem;
}

@media only screen and (max-width: 900px) {
  .contact-me-img > img {
    width: 250px;
    border: 1px solid gray;
    border-radius: 0.2rem;
  }

  .contact-me-img {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .contact-me-img > img {
    width: 160px;
    border: 1px solid gray;
    border-radius: 0.2rem;
  }

  .contact-me-img {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 756px) {
  .devider {
    max-width: 900px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .contact-me-img > img {
    width: 200px;
    border: 1px solid gray;
    border-radius: 0.2rem;
  }

  .contact-me-img {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
