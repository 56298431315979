.items-grid-container {
  max-width: 900px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
}

.item-container {
  flex-basis: 25%;
  height: 280px;
  //   border: 1px solid black;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-img-container {
  background-color: gray;
  width: 200px;
  height: 100%;
  overflow: hidden;
  border-radius: 0.2rem;
}

.item-img {
  width: 100%;
}

.buy-guidance-text {
  font-size: 1rem;
  font-weight: 500;
  max-width: 900px;
  width: 75%;
  margin: 0 auto;
  margin-top: 2rem;
  font-style: italic;
}

.buy-guidance-text > .highlight {
  color: red;
  font-weight: 900;
}

@media only screen and (max-width: 954px) {
  .item-container {
    flex-basis: calc(100% / 3);
  }
}

@media only screen and (max-width: 700px) {
  .item-container {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .item-container {
    flex-basis: 100%;
  }
}
