.qna-container {
  max-width: 900px;
  width: 75%;
  margin: 1.5rem auto;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 0.2rem;
}

@media only screen and (max-width: 750px) {
  .qna-container {
    width: 95%;
  }
}

.question-header,
.question-payload {
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  min-height: 46px;
  height: fit-content;
  position: relative;
  border-radius: 0.3rem;
}

.question-header {
  cursor: pointer;
}

.question-icon {
  width: 50px;
  background-color: rgb(216, 39, 27);
  border: 3px solid #e9e9e9;
  border-radius: 0.3rem;
  min-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.4rem;
  font-weight: 900;
}

.question-text {
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
  color: black;
  padding: 0.4rem;
  padding-left: 60px;
}

.question-view-answer-btn {
  padding: 0 1rem;
  cursor: pointer;
}

.question-payload {
  display: none;
  padding-right: 2.1rem;
  font-style: italic;
  font-size: 0.8rem;
  color: rgb(141, 141, 141) !important;
}

.question-payload > .question-text {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(56, 56, 56);
}

.question-payload > .question-icon {
  background-color: rgb(121, 121, 121);
}

.question-payload.open {
  display: flex;
}

@media only screen and (max-width: 325px) {
  .qna-container {
    width: 97%;
  }

  .question-text {
    font-size: 0.8rem;
  }

  .question-payload > .question-text {
    font-size: 0.8rem;
  }
}
