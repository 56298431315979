.gallery-section-title {
  display: flex;
  align-items: flex-end;
  width: 96%;
  padding: 20px 15px;
  margin: 0 auto;
}

.gallery-section {
  margin-bottom: 40px;
}

.gallery-section-wrapper {
  width: 96%;
  margin: 0 auto;
  height: fit-content !important;
  // background-color: gray;
  display: flex;
  flex-wrap: wrap;
}

.gallery-section-wrapper-item {
  // border: 1px solid red;
  // background-color: silver;
  display: inline-block;
  height: 0;
  padding-bottom: 25%;
  width: 25%;
  max-width: 480px;
  position: relative;
  // margin: 0 auto;
}

@media only screen and (min-width: 1000px) {
  .gallery-section-wrapper-item {
    padding-bottom: calc(min(480px, 25%));
  }
}

@media only screen and (max-width: 1000px) {
  .gallery-section-wrapper-item {
    padding-bottom: calc(100% / 3);
    width: calc(100% / 3);
  }
}

@media only screen and (max-width: 854px) {
  .gallery-section-wrapper-item {
    padding-bottom: 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .gallery-section-wrapper-item {
    padding-bottom: 100%;
    width: 100%;
  }
}

.gallery-img-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 16px;
}
