.my-story-section {
  background-color: #e9e9e9 !important;
  // padding-top: 90px;
}

.my-story-wrapper {
  max-width: 900px;
  width: 75%;
  margin: 0 auto;
}

.my-story-para-breaker,
.author-name {
  margin-bottom: 1.4rem;
  font-size: 1.1rem;
  font-weight: 500;
  font-style: oblique;
  text-align: justify;
}

.author-name {
  margin: 0;
  font-style: italic;
}

@media only screen and (max-width: 750px) {
  .my-story-wrapper {
    width: 95%;
  }
}
