.gallery-img-card {
  position: relative;
}

.gallery-img-card-rotated {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: rgb(231, 231, 231);
  border: 3px solid rgb(255, 255, 255);
  transform-origin: center center;
  overflow: hidden;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
}

.gallery-img-card-rotated > img {
  width: 100%;
  height: 100%;
}

// .gallery-img-card-rotated:nth-child(1) {
//   transform: rotate(1deg);
// }

.gallery-img-card-rotated:nth-child(1) {
  transform: rotate(1deg);
}

.gallery-img-card-rotated:nth-child(2) {
  transform: rotate(-2deg);
}

.gallery-img-card-rotated:nth-child(3) {
  transform: rotate(-4deg);
}
