.main-section-container {
  width: 100%;
  height: fit-content;
}

.main-section-wrapper {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 2rem;
  padding-top: 90px;
  background-color: rgb(245, 245, 245);
  display: flow-root;
}

@media only screen and (max-width: 484px) {
  .main-section-wrapper {
    padding: 2rem 0.3rem;
  }
}

// ---------- Main Image-------------------

.test {
  float: left;
  shape-margin: 1rem;
  width: 38vw;
  -webkit-filter: drop-shadow(-5px 3px 5px rgba(0, 0, 0, 0.8));
  filter: drop-shadow(-5px 3px 5px rgba(0, 0, 0, 0.8));
}

@media only screen and (max-width: 750px) {
  .test {
    width: 100%;
  }
}

// ---------- Main Title-------------------
.hero-title {
  font-weight: 900;
  font-size: 5.4rem;
  padding: 0 3rem;
  padding-top: 2rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.hero-title-span {
  // color: #8e0c3d;
  // background: -webkit-linear-gradient(left, #8e0c3d, #790833);
  color: #8e0c3d;
  background: -webkit-linear-gradient(left, #5dc0fd, #0486ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 750px) {
  .hero-title {
    font-size: 3.6rem;
    padding: 0 1rem;
    justify-content: center;
  }
}

// ---------- Second Title-------------------

.hero-second-title-container {
  text-align: right;
  padding: 0 3rem;
  margin-top: 2.5rem;
}

.hero-second-title {
  font-weight: 700;
  font-size: 3rem;
  font-style: italic;
}

@media only screen and (max-width: 750px) {
  .hero-second-title-container {
    // font-size: 3.6rem;
    padding: 0 1rem;
    text-align: center;
  }

  .hero-second-title {
    font-size: 1.8rem;
    font-weight: 900;
  }
}

// ---------- Main Description-------------------

.hero-desription-container {
  padding: 0 3rem;
  margin-top: 2.2rem;
}

.hero-description {
  font-weight: 500;
  font-style: italic;
  font-size: 1.2rem;
  text-align: justify;
}

@media only screen and (max-width: 750px) {
  .hero-desription-container {
    padding: 0 1rem;
  }

  .hero-description {
    font-weight: 500;
    font-size: 1.1rem;
  }
}

// ----------Hero Btn-------------------

.hero-btn-container {
  display: flex;
  justify-content: center;
  padding: 0 3rem;
  padding-top: 2rem;
  margin-top: 2rem;
}

.hero-btn {
  padding: 1rem 2rem;
  color: white;
  white-space: nowrap;
  // background-color: #8e0c3d;
  background-color: #39a9f6;
  border: none;
  border-radius: 1rem;
  font-weight: 900;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;
}

.hero-btn-icon {
  margin-right: 0.6rem;
}
