.social-link-container {
  margin: 1rem 0;
}

.social-link-content {
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.social-link-name {
  margin-left: 1rem;
}

.social-icon-wrapper {
  width: 40px;
}

@media only screen and (max-width: 440px) {
  .social-icon-wrapper {
    width: 30px;
  }
  .social-link-content {
    font-size: 1rem;
  }
}
