.footer {
  width: 100%;
  // height: 90px;
  min-height: 90px;
  height: fit-content;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.footer > div {
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.copyright-info {
  flex: 2;
  justify-content: center;
}

.footer-placeholder {
  flex: 1;
}

.developer-info {
  flex: 1;
  justify-content: center;
}

.footer-text {
  font-size: 1rem;
  color: white;
  text-align: center;
}

.developer-contact-link {
  text-decoration: none;
  font-style: italic;
  font-weight: 500;
  color: rgb(29, 155, 240);
}

@media only screen and (max-width: 854px) {
  .footer {
    flex-direction: column;
  }

  .developer-info {
    padding: 0 !important;
    padding-bottom: 60px !important;
  }
}
