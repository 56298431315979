$amber300: #fcd34d;
$amber400: #fbbf24;
$amber500: #f59e0b;

.nav-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 90px;
  background-color: black;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

/*--------Logo styles---------*/

.logo-container {
  display: flex;
  align-items: center;
}

.logo-img {
  border: 0.1rem solid white;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  padding: 0.1rem;
  margin-right: 0.7rem;
}

.logo-text {
  color: white;
  font-weight: 900;
}

.logo-txt-link {
  text-decoration: none;
}

.logo-text-span {
  color: $amber500;
  font-style: italic;
}

/*--------Nav links styles---------*/

.nav-links-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .nav-links-container {
    display: none;
  }
}

.nav-links-left-sep > *,
.nav-links-right-sep > * {
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
}

.nav-links-left-sep {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links-right-sep {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link-icon {
  margin-right: 0.5rem;
}

.nav-links-seperator {
  color: white;
  margin: 0 2rem;
  font-weight: 700;
  font-size: 1rem;
}

/*--------Burger Menu styles---------*/
.burger-menu-container {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

@media only screen and (min-width: 769px) {
  .burger-menu-container {
    display: none;
  }
}

.burger-menu {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.burger-slice {
  width: 1.7rem;
  height: 0.16rem;
  background-color: white;
  border-radius: 3rem;
  margin: 0.16rem 0;
  transition: 0.2s ease-in-out;
}

.burger-slice:nth-child(2) {
  transition: opacity 0.2s 0.05s ease-in-out transform 0.2s ease-in-out;
}

.burger-slice:nth-child(1) {
  transform-origin: center center;
}

.burger-menu.open > .burger-slice:nth-child(2) {
  transform: translateX(10px);
  opacity: 0;
}

.burger-menu.open > .burger-slice:nth-child(1) {
  transform-origin: center center;
  transform: translateY(0.45rem) rotate(45deg);
}

.burger-menu.open > .burger-slice:nth-child(3) {
  transform-origin: center center;
  transform: translateY(-0.45rem) rotate(-45deg);
}

/*--------Burger Menu styles---------*/
.slide-menu {
  position: absolute;
  top: 90px;
  left: -100%;
  z-index: 10;
  width: 100%;
  min-height: calc(100vh - 90px);
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

.slide-menu.open {
  left: 0;
}

.slide-menu-main-links-container {
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.slide-menu-main-links-container > .nav-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1.5rem 0;
}

.slide-menu-seperator {
  width: 70vw;
  height: 0.05rem;
  background-color: rgb(170, 170, 170);
  margin: 1rem 0;
}

.slide-menu-social-media-links-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-menu-social-media-links-container > .nav-social-media-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 1.2rem;
}
