.qna-section-container {
  width: 100%;
  height: fit-content;
  // min-height: 100vh;
}

.qna-wrapper {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  padding: 2rem;
  // padding-top: 90px;
  background-color: #f3f3f3;
}

.section-title {
  max-width: 900px;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-end;
}

.section-title-text {
  font-size: 1.4rem;
  font-weight: 900;
  color: rgb(216, 39, 27);
}

.section-title-style {
  width: 100px;
  height: 1px;
  background-color: rgb(216, 39, 27);
}

@media only screen and (max-width: 750px) {
  .section-title {
    width: 95%;
  }
}
